import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../store';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(http, store, rootStore, router) {
        this.http = http;
        this.store = store;
        this.rootStore = rootStore;
        this.router = router;
    }
    AuthService.prototype.login = function (payload) {
        var _this = this;
        return this.http
            .post("" + environment.api_base + environment.oauth_path, tslib_1.__assign({ grant_type: 'password', client_id: environment.oauth_client_id, client_secret: environment.oauth_client_secret, ticketer: environment.ticketer }, payload))
            .pipe(switchMap(function (response) {
            localStorage.setItem('backoffice_access_token', response.access_token);
            return _this.getMe();
        }), switchMap(function (user) {
            if (!user.companies.length) {
                _this.router.navigate(['/register/complete-registration']);
            }
            else {
                _this.store.dispatch(new fromAuth.Authenticate(user));
            }
            return of(user);
        }));
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        this.router.navigate(['/login']);
        localStorage.removeItem('backoffice_access_token');
        setTimeout(function () {
            _this.store.dispatch(new fromAuth.Unauthenticate());
        }, 50);
    };
    AuthService.prototype.loginFromStorage = function () {
        var _this = this;
        this.getMe().subscribe(function (user) {
            if (!user.companies.length) {
                _this.router.navigate(['/register/complete-registration']);
            }
            else {
                _this.store.dispatch(new fromAuth.Authenticate(user));
            }
        }, function (e) {
            window.location.href = environment.url + "/login";
        });
    };
    AuthService.prototype.getMe = function () {
        return this.http.get("/me").pipe(map(function (data) { return data.data; }));
    };
    AuthService.prototype.getAuthorizationHeader = function () {
        return 'Bearer ' + localStorage.getItem('backoffice_access_token');
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i2.Store), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
