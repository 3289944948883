import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import * as fromRoot from '../../../store';
import * as fromAuth from '../../store';

import { RegisterService } from '../../services';
import { User } from '../../models/user.model';
import { environment } from '@environments/environment';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]],
    phone: [null, Validators.required],
    ticketer: [null, Validators.required]
  });

  public loading = false;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.form.patchValue({ ticketer: environment.ticketer });
  }

  onSubmit() {
    if (!this.form.valid || this.loading) {
      return;
    }
    this.loading = true;

    this.registerService.postRegister(this.form.value).subscribe(
      (response: User) => {
        this.loading = false;
        this.toastService.success(
          `You need to verify your email before you can login. Please check your email inbox.`,
          8000
        );
        this.router.navigate(['/login']);
      },
      e => {
        this.loading = false;
        console.log(e);
        alert(`Something went wrong`);
      }
    );
  }
}
