import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import * as fromAuth from '../store';
import * as fromRoot from '../../store';

import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private rootStore: Store<fromRoot.State>,
    private router: Router
  ) {}

  public login(payload: any): Observable<User> {
    return this.http
      .post(`${environment.api_base}${environment.oauth_path}`, {
        grant_type: 'password',
        client_id: environment.oauth_client_id,
        client_secret: environment.oauth_client_secret,
        ticketer: environment.ticketer,
        ...payload
      })
      .pipe(
        switchMap((response: any) => {
          localStorage.setItem(
            'backoffice_access_token',
            response.access_token
          );
          return this.getMe();
        }),
        switchMap((user: User) => {
          if (!user.companies.length) {
            this.router.navigate(['/register/complete-registration']);
          } else {
            this.store.dispatch(new fromAuth.Authenticate(user));
          }
          return of(user);
        })
      );
  }

  public logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('backoffice_access_token');
    setTimeout(() => {
      this.store.dispatch(new fromAuth.Unauthenticate());
    }, 50);
  }

  public loginFromStorage(): void {
    this.getMe().subscribe(
      (user: User) => {
        if (!user.companies.length) {
          this.router.navigate(['/register/complete-registration']);
        } else {
          this.store.dispatch(new fromAuth.Authenticate(user));
        }
      },
      e => {
        window.location.href = `${environment.url}/login`;
      }
    );
  }

  public getMe(): Observable<User> {
    return this.http.get(`/me`).pipe(map((data: { data: any }) => data.data));
  }

  public getAuthorizationHeader(): string {
    return 'Bearer ' + localStorage.getItem('backoffice_access_token');
  }
}
