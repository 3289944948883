import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { slideInOut } from '@shared/animations/animations';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
  animations: [slideInOut]
})
export class NotificationBoxComponent implements OnInit {
  public show = true;

  @Output() closed = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  closeBtn(e: PointerEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.show = false;

    setTimeout(() => {
      this.closed.emit();
    }, 300);
  }
}
