import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import * as fromRoot from '../../../store';
import * as fromAuth from '../../store';

import { AuthService, RegisterService } from '../../services';
import { User } from '../../models/user.model';
import { ToastService } from '@shared/services/toast.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    username: ['robert@bitscan.net', Validators.required],
    password: ['secret', Validators.required]
  });

  public loading = false;
  public errorMsg: string;
  public state$;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private registerService: RegisterService,
    private router: Router,
    private store: Store<fromAuth.AuthState>,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    // const state$ = this.store.pipe(select(fromAuth.getUser));
    // state$.subscribe(user => {
    //   console.log(user);
    // });
  }

  onSubmit() {
    if (!this.form.valid || this.loading) {
      return;
    }
    this.errorMsg = null;
    this.loading = true;

    this.authService.login(this.form.value).subscribe(
      (response: User) => {
        this.loading = false;
        this.router.navigate(['/backoffice']);
      },
      e => {
        console.log(e);
        if (e.error.message === 'Your email address is not verified.') {
          this.errorMsg = 'unverified_email';
        } else if (e.error.error) {
          this.errorMsg = e.error.error;
        }
        this.loading = false;
      }
    );
  }

  resendVerificationEmailBtn(e: PointerEvent) {
    e.preventDefault();
    const email = this.form.get('username').value;

    this.registerService
      .postResendVerification({
        email,
        ticketer: environment.ticketer
      })
      .subscribe(res => {
        this.errorMsg = null;
        this.toastService.success(
          `Verification email has been sent to ${email}.`,
          8000
        );
      });
  }
}
