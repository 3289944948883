import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import * as fromRoot from '../../../store';
import * as fromAuth from '../../store';

import { AuthService, RegisterService } from '../../services';
import { User } from '../../models/user.model';
import { environment } from '@environments/environment';
import { ToastService } from '@shared/services/toast.service';
import { Company } from '@auth/models/company.model';
import { forkJoin, combineLatest } from 'rxjs';
import { take, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { validateZipCode } from '@shared/helpers/validators';
import { PostalCodeLookupResponse } from '@shared/models';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    name: [null, Validators.required],
    kvk: [null, Validators.required],
    btw_nr: [null, Validators.required],
    address: this.fb.group({
      street: [null, Validators.required],
      number: [null, Validators.required],
      zipcode: [null, [Validators.required, validateZipCode]],
      city: [null, Validators.required],
      country: ['The Netherlands', Validators.required]
    })
  });

  public loading = false;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    combineLatest(
      this.form.get('address.zipcode').valueChanges,
      this.form.get('address.number').valueChanges
    )
      .pipe(
        filter(
          _ =>
            this.form.get('address.zipcode').valid &&
            this.form.get('address.number').valid
        ),
        debounceTime(150),
        switchMap(form =>
          this.registerService.getPostalCodeLookup(form[0], form[1])
        ),
        filter(_ => !!_)
      )
      .subscribe((response: PostalCodeLookupResponse) => {
        this.form.patchValue(
          {
            address: {
              zipcode: response.zip_code,
              number: response.house_number,
              city: response.city,
              street: response.street
            }
          },
          {
            emitEvent: false
          }
        );
      });

    this.registerService.getCompanies().subscribe(
      companies => {
        if (companies.length) {
          this.toastService.error(
            `You have already completed your registration.`
          );
          this.router.navigate(['/backoffice']);
        }
      },
      error => {
        if (error.status === 401) {
          this.toastService.error(
            `Please login to complete your registration.`
          );
          this.router.navigate(['/login']);
        }
      }
    );
  }

  onSubmit() {
    if (!this.form.valid || this.loading) {
      return;
    }
    this.loading = true;

    this.registerService.postCompanies(this.form.value).subscribe(
      (response: Company) => {
        this.toastService.success(`You have completed your registration.`);
        this.router.navigate(['/backoffice']);
      },
      e => {
        this.loading = false;
        console.log(e);
        alert(`Something went wrong`);
      }
    );
  }
}
