import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Company } from '@auth/models/company.model';
import { map, catchError } from 'rxjs/operators';
import { PostalCodeLookupResponse } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  public postRegister(payload): Observable<any> {
    return this.http.post(`/users`, payload);
  }

  public postResendVerification(payload: {
    email: string;
    ticketer: string;
  }): Observable<any> {
    return this.http.post(`/resend-verification`, payload);
  }

  postCompanies(payload): Observable<any> {
    return this.http.post(`/companies`, payload);
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`/companies`).pipe(map((_: any) => _.data));
  }

  getPostalCodeLookup(
    postalCode: string,
    number: string
  ): Observable<PostalCodeLookupResponse> {
    return this.http
      .get<PostalCodeLookupResponse>(`/postcode/${postalCode}/${number}`)
      .pipe(
        catchError(e => {
          return of(null);
        })
      );
  }
}
