<div class="page-wrap" fxLayout="row" fxLayoutAlign="center center">
	<form
		class="form-signin form-full-width"
		[formGroup]="form"
		(ngSubmit)="onSubmit()"
	>
		<h1>Sign In</h1>

		<!-- invalid_credentials -->

		<div
			class="login-notification"
			*ngIf="errorMsg === 'invalid_credentials'"
		>
			<app-notification-box (closed)="errorMsg = null"
				>Invalid credentials</app-notification-box
			>
		</div>

		<div class="login-notification" *ngIf="errorMsg === 'unverified_email'">
			<app-notification-box (closed)="errorMsg = null"
				>Email adress not verified.
				<a href="#" (click)="resendVerificationEmailBtn($event)"
					>Resend verification email?</a
				></app-notification-box
			>
		</div>

		<input
			type="text"
			placeholder="Email"
			class="margin-top"
			formControlName="username"
		/>
		<input
			placeholder="Password"
			formControlName="password"
			type="password"
		/>

		<div fxLayout="row" fxLayoutAlign="space-between center">
			<div>
				<button
					class="btn btn-primary btn-block"
					color="primary"
					type="submit"
					*ngIf="!loading"
				>
					Sign in to Account
				</button>
				<button
					class="btn btn-clear btn-block"
					disabled
					*ngIf="loading"
				>
					<i class="far fa-spinner fa-spin"></i>
				</button>
			</div>

			<div>
				<a class="btn btn-clear" routerLink="/forgot-password"
					>Forgot Password?</a
				>
			</div>
		</div>

		<hr />

		<a
			routerLink="/register"
			class="btn btn-primary btn-block"
			color="primary"
		>
			Register Account
		</a>

		<!-- <p class="copy">&copy; 2018 Payless Events BV</p> -->
	</form>
</div>
