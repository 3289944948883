<div class="page-wrap" fxLayout="row" fxLayoutAlign="center center">
	<form
		class="form-signin form-full-width"
		[formGroup]="form"
		(ngSubmit)="onSubmit()"
	>
		<h1>Complete Registration</h1>

		<h3>We just need a few more details about your company</h3>

		<div class="form-field">
			<div class="form-field__title">Company Name</div>
			<input type="text" formControlName="name" />
		</div>
		<div class="form-field">
			<div class="form-field__title">KVK</div>
			<input type="text" formControlName="kvk" />
		</div>
		<div class="form-field">
			<div class="form-field__title">BTW</div>
			<input type="text" formControlName="btw_nr" />
		</div>
		<br />
		<h3>Your Billing Address</h3>
		<div formGroupName="address">
			<div
				fxLayout="row"
				fxLayoutAlign="space-between center"
				fxLayoutGap="2rem"
			>
				<div class="form-field" fxFlex="calc(50% - 2rem)">
					<div class="form-field__title">Postal Code</div>
					<input type="text" formControlName="zipcode" />
				</div>
				<div class="form-field" fxFlex="calc(50% - 2rem)">
					<div class="form-field__title">Number</div>
					<input type="text" formControlName="number" />
				</div>
			</div>
			<div class="form-field">
				<div class="form-field__title">Street</div>
				<input type="text" formControlName="street" />
			</div>
			<div class="form-field">
				<div class="form-field__title">City</div>
				<input type="text" formControlName="city" />
			</div>
			<div class="form-field margin-bottom">
				<div class="form-field__title">Country</div>
				<ng-select
					[items]="[
						{ name: 'The Netherlands', value: 'The Netherlands' }
					]"
					bindLabel="name"
					bindValue="value"
					[searchable]="false"
					formControlName="country"
					appendTo="body"
					[clearable]="false"
				>
				</ng-select>
			</div>
		</div>
		<button
			class="btn btn-primary btn-block"
			color="primary"
			type="submit"
			*ngIf="!loading"
			[attr.disabled]="!form.valid || loading ? 'disabled' : null"
		>
			Submit
		</button>

		<hr />
		<a class="btn btn-clear" routerLink="/login">Back to Login</a>

		<!-- <p class="copy">&copy; 2018 Payless Events BV</p> -->
	</form>
</div>
