import * as tslib_1 from "tslib";
import * as fromAuth from '../actions/auth.action';
export var initialState = {
    user: null,
    company: null,
    authenticated: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromAuth.AUTHENTICATE: {
            var user = action.payload;
            var companyUuid_1 = localStorage.getItem('bitscan_active_company');
            var company = user.companies.find(function (c) { return c.uuid === companyUuid_1; }) || user.companies[0];
            localStorage.setItem('bitscan_active_company', company.uuid);
            return tslib_1.__assign({}, state, { authenticated: true, user: user, company: company });
        }
        case fromAuth.UNAUTHENTICATE: {
            localStorage.removeItem('bitscan_active_company');
            return tslib_1.__assign({}, state, { user: null, company: null, authenticated: false });
        }
        case fromAuth.SELECT_COMPANY_UUID: {
            var companyUuid_2 = action.payload;
            localStorage.setItem('bitscan_active_company', companyUuid_2);
            return tslib_1.__assign({}, state, { company: state.user.companies.find(function (c) { return c.uuid === companyUuid_2; }) ||
                    state.user.companies[0] });
        }
    }
    return state;
}
export var getUserState = function (state) { return state.user; };
export var getCompanyState = function (state) { return state.company; };
export var getIsAuthenticatedState = function (state) {
    return state.authenticated;
};
