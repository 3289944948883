<div class="page-wrap" fxLayout="row" fxLayoutAlign="center center">
	<form
		class="form-signin form-full-width"
		[formGroup]="form"
		(ngSubmit)="onSubmit()"
	>
		<h1>Register Account</h1>

		<div class="form-field">
			<div class="form-field__title">Name</div>
			<input type="text" formControlName="name" />
		</div>
		<div class="form-field">
			<div class="form-field__title">Email</div>
			<input type="text" formControlName="email" />
		</div>
		<div class="form-field">
			<div class="form-field__title">Phone</div>
			<input type="text" formControlName="phone" />
		</div>
		<div class="form-field">
			<div class="form-field__title">Password</div>
			<input type="password" formControlName="password" />
		</div>

		<button
			class="btn btn-primary btn-block"
			color="primary"
			type="submit"
			*ngIf="!loading"
			[attr.disabled]="!form.valid || loading ? 'disabled' : null"
		>
			Create Account
		</button>

		<hr />
		<a class="btn btn-clear" routerLink="/login">Back to Login</a>

		<!-- <p class="copy">&copy; 2018 Payless Events BV</p> -->
	</form>
</div>
