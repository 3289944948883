import { Action } from '@ngrx/store';
import { Event } from '@shared/models/event.interface';

export const UNLOAD_EVENT = '[Event] Unload';
export const LOAD_EVENT = '[Event] Load';
export const LOADED_EVENT = '[Event] Loaded';

export class UnloadEvent implements Action {
  readonly type = UNLOAD_EVENT;
  constructor() {}
}

export class LoadedEvent implements Action {
  readonly type = LOADED_EVENT;
  constructor(public payload: Event) {}
}

export class LoadEvent implements Action {
  readonly type = LOAD_EVENT;
  constructor(public payload: string) {}
}

export type EventAction = LoadEvent | LoadedEvent | UnloadEvent;
