import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromAuth from '../store';

import { AuthService } from '../services';

@Injectable()
export class CompleteRegistrationGuard implements CanActivate {
  constructor(
    private store: Store<fromAuth.AuthState>,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return of(true);
    // return this.store.select(fromAuth.getAuthenticated).pipe(
    //   tap(authenticated => {
    //     if (!authenticated) {
    //       this.authService.loginFromStorage();
    //     }
    //   }),
    //   filter(authenticated => !!authenticated),
    //   take(1)
    // );
  }
}
