import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';

import * as fromComponents from './components';
import * as fromPipes from './pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';

const materialModules: any[] = [
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule
];

@NgModule({
  declarations: [...fromComponents.components, ...fromPipes.pipes, ImageUploadComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    NgSelectModule,
    TranslateModule,
    ...materialModules
  ],
  exports: [
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...materialModules,
    FormsModule,
    NgSelectModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class SharedModule {}
