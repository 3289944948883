import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromAuth from '../reducers';

export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');

export const getUser = createSelector(
  getAuthState,
  fromAuth.getUserState
);

export const getAuthenticated = createSelector(
  getAuthState,
  fromAuth.getIsAuthenticatedState
);
export const getCompany = createSelector(
  getAuthState,
  fromAuth.getCompanyState
);

export const getUserCompanies = createSelector(
  getUser,
  user => {
    return (user && user.companies) || [];
  }
);
