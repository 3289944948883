import * as fromAuth from '../actions/auth.action';
import { User } from '../../models/user.model';
import { Company } from '../../models/company.model';

export interface AuthState {
  user: User;
  company: Company;
  authenticated: boolean;
}

export const initialState: AuthState = {
  user: null,
  company: null,
  authenticated: false
};

export function reducer(
  state = initialState,
  action: fromAuth.AuthAction
): AuthState {
  switch (action.type) {
    case fromAuth.AUTHENTICATE: {
      const user = action.payload;
      const companyUuid = localStorage.getItem('bitscan_active_company');
      const company =
        user.companies.find(c => c.uuid === companyUuid) || user.companies[0];
      localStorage.setItem('bitscan_active_company', company.uuid);

      return {
        ...state,
        authenticated: true,
        user,
        company: company
      };
    }
    case fromAuth.UNAUTHENTICATE: {
      localStorage.removeItem('bitscan_active_company');
      return {
        ...state,
        user: null,
        company: null,
        authenticated: false
      };
    }
    case fromAuth.SELECT_COMPANY_UUID: {
      const companyUuid: string = action.payload;
      localStorage.setItem('bitscan_active_company', companyUuid);
      return {
        ...state,
        company:
          state.user.companies.find(c => c.uuid === companyUuid) ||
          state.user.companies[0]
      };
    }
  }

  return state;
}

export const getUserState = (state: AuthState) => state.user;
export const getCompanyState = (state: AuthState) => state.company;
export const getIsAuthenticatedState = (state: AuthState) =>
  state.authenticated;
