import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@shared/services/toast.service';

const IGNORE_URLS = [environment.oauth_path];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private inj: Injector,
    private _router: Router,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthService);
    const authToken = auth.getAuthorizationHeader();
    // Get the auth header from the service.
    const authHeader = authToken;
    // Clone the request to add the new header.
    let headers = req.headers
      .set('Authorization', authHeader)
      .set('Company', localStorage.getItem('bitscan_active_company'));
    if (
      req.url.indexOf('https://') === 0 ||
      req.url.indexOf('http://') === 0 ||
      !localStorage.getItem('bitscan_active_company')
    ) {
      headers = req.headers.set('Authorization', authHeader);
    }
    const authReq = req.clone({
      headers: headers,
      url:
        req.url.indexOf('https://') === 0 ||
        req.url.indexOf('http://') === 0 ||
        req.url.indexOf('/assets') === 0
          ? req.url
          : environment.api_base + environment.api_path + req.url
    });
    // console.warn('intercept...', authToken);
    // Pass on the cloned request instead of the original request.
    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              console.log('got a 401!', req);
              if (IGNORE_URLS.indexOf(req.url) === -1) {
                // window.location.href = `${
                //   environment.url
                // }/login?redirect=${encodeURIComponent('')}`;
              }
            } else if (err.status === 500) {
              console.error(
                'Whoops, something went wrong. Our devs have been notified. Try again, or contact support.'
              );
            }

            if (err.error.message) {
              this.toastService.error(err.error.message);
            } else if (err.message) {
              this.toastService.error(err.message);
            }
          }
        }
      )
    );
  }
}
