/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notification-box.component";
var styles_NotificationBoxComponent = [i0.styles];
var RenderType_NotificationBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationBoxComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { height: "*", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { height: "*", opacity: 1 }, offset: null }, { type: 3, steps: [{ type: 4, styles: { type: 6, styles: { height: 0 }, offset: null }, timings: 300 }, { type: 4, styles: { type: 6, styles: { opacity: "0" }, offset: null }, timings: "200ms ease-in-out" }], options: null }], options: null }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { height: "0", opacity: 0 }, offset: null }, { type: 3, steps: [{ type: 4, styles: { type: 6, styles: { height: "*" }, offset: null }, timings: 300 }, { type: 4, styles: { type: 6, styles: { opacity: "1" }, offset: null }, timings: "400ms ease-in-out" }], options: null }], options: null }], options: {} }] } });
export { RenderType_NotificationBoxComponent as RenderType_NotificationBoxComponent };
function View_NotificationBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "box__msg"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "box__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBtn($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fal fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBoxComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-box", [], null, null, null, View_NotificationBoxComponent_0, RenderType_NotificationBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationBoxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationBoxComponentNgFactory = i1.ɵccf("app-notification-box", i3.NotificationBoxComponent, View_NotificationBoxComponent_Host_0, {}, { closed: "closed" }, ["*"]);
export { NotificationBoxComponentNgFactory as NotificationBoxComponentNgFactory };
