import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegisterService = /** @class */ (function () {
    function RegisterService(http) {
        this.http = http;
    }
    RegisterService.prototype.postRegister = function (payload) {
        return this.http.post("/users", payload);
    };
    RegisterService.prototype.postResendVerification = function (payload) {
        return this.http.post("/resend-verification", payload);
    };
    RegisterService.prototype.postCompanies = function (payload) {
        return this.http.post("/companies", payload);
    };
    RegisterService.prototype.getCompanies = function () {
        return this.http.get("/companies").pipe(map(function (_) { return _.data; }));
    };
    RegisterService.prototype.getPostalCodeLookup = function (postalCode, number) {
        return this.http
            .get("/postcode/" + postalCode + "/" + number)
            .pipe(catchError(function (e) {
            return of(null);
        }));
    };
    RegisterService.ngInjectableDef = i0.defineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.inject(i1.HttpClient)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
