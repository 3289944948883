import * as fromEvent from '../actions/event.action';
import { Event } from '@shared/models/event.interface';

export interface EventState {
  event: Event;
  loaded: boolean;
  loading: boolean;
}

export const initialState: EventState = {
  event: null,
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: fromEvent.EventAction
): EventState {
  switch (action.type) {
    case fromEvent.LOAD_EVENT: {
      return {
        ...state,
        loading: true,
        loaded: false,
        event: null
      };
    }
    case fromEvent.LOADED_EVENT: {
      const event: Event = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        event
      };
    }
    case fromEvent.UNLOAD_EVENT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        event: null
      };
    }
  }

  return state;
}

export const getEventState = (state: EventState) => state.event;
export const getLoadedState = (state: EventState) => state.loaded;
export const getLoadingState = (state: EventState) => state.loading;
