export const environment = {
  production: true,
  url: 'https://staging-backoffice.bitscan.net',
  ticketer: 'bitscan',
  api_base: 'https://staging-api.bitscan.net',
  api_path: '/api/v1/backoffice',
  oauth_path: '/oauth/token',
  oauth_client_id: 2,
  oauth_client_secret: 'SNkwUkUgzjLr84WsH1R4q7c6npnDPyfiS8vXSVpj'
};
