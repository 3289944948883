import * as tslib_1 from "tslib";
import * as fromEvent from '../actions/event.action';
export var initialState = {
    event: null,
    loaded: false,
    loading: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromEvent.LOAD_EVENT: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false, event: null });
        }
        case fromEvent.LOADED_EVENT: {
            var event_1 = action.payload;
            return tslib_1.__assign({}, state, { loading: false, loaded: true, event: event_1 });
        }
        case fromEvent.UNLOAD_EVENT: {
            return tslib_1.__assign({}, state, { loading: false, loaded: false, event: null });
        }
    }
    return state;
}
export var getEventState = function (state) { return state.event; };
export var getLoadedState = function (state) { return state.loaded; };
export var getLoadingState = function (state) { return state.loading; };
