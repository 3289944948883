import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export const AUTHENTICATE = '[Auth] Authenticate';
export const UNAUTHENTICATE = '[Auth] Unauthenticate';
export const SELECT_COMPANY_UUID = '[Auth] Select Company Uuid';

export class Authenticate implements Action {
  readonly type = AUTHENTICATE;

  constructor(public payload: User) {}
}

export class Unauthenticate implements Action {
  readonly type = UNAUTHENTICATE;

  constructor() {}
}

export class SelectCompanyUuid implements Action {
  readonly type = SELECT_COMPANY_UUID;

  constructor(public payload: string) {}
}

export type AuthAction = Authenticate | Unauthenticate | SelectCompanyUuid;
